import React, { useState } from 'react'
const data = require( '../attributes.json')

export default function Attributes () {
  data[0].items.sort((a,b) => (a.trait > b.trait) ? 1 : ((b.trait > a.trait) ? -1 : 0))
  const [showResults, setShowResults] = useState(data[0])
  const [activeLink, setActiveLink] = useState('BACKGROUND')
  function clickHandler (e) {
    e.preventDefault()
    const id = e.target.getAttribute('data-id')
    const attribute = data.find(attr => attr.name === id)
    if (attribute) {
      attribute.items.sort((a,b) => (a.trait > b.trait) ? 1 : ((b.trait > a.trait) ? -1 : 0))
    } else {
      data[0].items.sort((a,b) => (a.trait > b.trait) ? 1 : ((b.trait > a.trait) ? -1 : 0))
    }
    setShowResults(attribute ? attribute : data[0])
    setActiveLink(id)
  }
  return (
    <section className="bg-gray-900 px-0 lg:py-32 py-20 px-6">
      <h2 className="text-5xl text-white mb-8 font-heading uppercase text-center mb-16">Attributes</h2>
      <div className="lg:flex text-white max-w-7xl mx-auto">
        <div className="lg:w-4/12 w-full lg:pr-20 lg:text-left text-center lg:mb-0 mb-20 lg:block flex flex-wrap justify-center">
          {
            data.map((attribute, k) => {
              return (
                <a href="" onClick={clickHandler} data-id={attribute.name} className={attribute.name === activeLink ? "text-green-400 block font-heading text-lg lg:mx-0 mx-2" : "font-heading block text-lg lg:mx-0 mx-2"} >{attribute.name}</a>
              )
            })
          }
        </div>
        <div className="w-8/12 w-full grid gap-6 lg:grid-cols-4 md:grid-cols-3 grid-cols-2">
          {
            showResults.items.map((item, k) => {
              return (
                <div className="rounded-lg bg-gray-800 flex flex-col items-stretch overflow-hidden transition duration-500 ease-in-out transform hover:scale-105 shadow-lg" key={k}>
                  <div className="">
                    <img src={item.image} />
                  </div>
                  <div className="text-center font-heading uppercase py-4 px-2 text-xl flex-1 flex items-center justify-center">
                    <span>{item.trait}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}
