import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import RandomImage from "./RandomProfile";
import Faq from './Faq'

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function Mint() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 25) {
      newMintAmount = 25;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <>
    <section className="bg-green-400 px-6 bg-cover bg-bottom" style={{backgroundImage: "url(" + "./burnedland-background.jpg" + ")"}}>
      <div className="xl:flex block xl:gap-12 lg:gap-0 container mx-auto items-end">
        <div className="xl:w-6/12 w-full xl:m-0 mb-12">
          <div className="xl:py-24 lg:py-12 pt-12 pb-0">
            <div className="bg-green-400 border-2 border-gray-900 bg-opacity-75 rounded-2xl shadow-2xl overflow-hidden">
              <div className="py-16 px-6">
              {blockchain.account === "" || blockchain.smartContract === null ? (
                <div className="text-center">
                  <div className="text-3xl font-heading uppercase mb-12 text-gray-900">
                    Connect to the {CONFIG.NETWORK.NAME} network
                  </div>
                  <button
                    className="lg:px-10 px-6 mx-auto flex items-center justify-between lg:py-6 py-4 shadow-xl font-heading uppercase mt-8 hover:bg-green-100 bg-white text-2xl rounded-xl border-2 border-gray-900"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getData();
                    }}
                  >
                    <img src="./metamask.svg" className="mr-2" />
                    <span className="flex-1">CONNECT METAMASK</span>
                  </button>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <div className="text-red-500 text-sm leading-relaxed font-semibold">
                        {blockchain.errorMsg}
                      </div>
                    </>
                  ) : null}
                </div>
              ) : (
                <div className="text-center">
                  <div className="text-center text-5xl font-black mb-4">
                    {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                  </div>
                  <div className="text-sm mb-12">
                    <a target={"_blank"} href={CONFIG.SCAN_LINK}>
                      {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                    </a>
                  </div>
                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                      <div>
                        The sale has ended.
                      </div>
                      <div>
                        You can still find {CONFIG.NFT_NAME} on
                        <a target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                          {CONFIG.MARKETPLACE}
                        </a>
                      </div>
                    </>
                    ) : (
                    <div>
                      <div className="font-heading uppercase text-3xl mb-2">
                        1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                        {CONFIG.NETWORK.SYMBOL}
                      </div>
                      <div className="mb-10 text-sm uppercase">
                        Excluding gas fees
                      </div>
                      {blockchain.account !== "" && blockchain.smartContract !== null ? (
                        <>
                          <div className="mb-8 font-semibold">
                            {feedback}
                          </div>
                          <div className="flex max-w-xs mx-auto items-center justify-center border-2 border-gray-900 rounded-xl shadow-lg bg-gray-900 flex-grow-0 overflow-hidden">
                            <button
                              className="font-black bg-white text-3xl px-6 py-2"
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementMintAmount();
                              }}
                            >
                              -
                            </button>
                            <div className="font-black bg-white text-3xl py-2">
                              {mintAmount}
                            </div>
                            <button
                              className="font-black bg-white text-3xl px-6 py-2"
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                              }}
                            >
                              +
                            </button>
                            <button
                              className="font-black text-3xl bg-gray-900 rounded-r-xl px-6 py-2 hover:bg-gray-800 flex-grow text-white"
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                claimNFTs();
                                getData();
                              }}
                            >
                              {claimingNft ? "BUSY" : "BUY"}
                            </button>
                          </div>
                        </>
                      ) : null}
                    </div>
                    )}
                </div>
              )}
              </div>
              <div className="text-xs leading-relaxed text-center text-gray-900 bg-white py-6 border-t-2 border-gray-900 px-6">
                Please make sure you are connected to the right network (
                {CONFIG.NETWORK.NAME} Mainnet) and the correct address. <br />
                Please note: Once you make the purchase, you cannot undo this action.<br />
                We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
                successfully mint your NFT. <br />We recommend that you don't lower the
                gas limit.
              </div>
            </div>
          </div>
        </div>
        <div className="xl:w-6/12 w-full">
          <RandomImage className="w-full" />
        </div>
      </div>
    </section>
    <Faq />
    </>
  );
}

export default Mint;
