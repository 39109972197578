import React from "react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Guardian from "./components/Guardian"
import Mint from './components/Mint'
import Home from './components/Home'
import Header from './components/Header'
import Footer from './components/Footer'
import NotFound from './components/NotFound'

function App() {
  return (
    <Router>
      <div>
        <Header />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/guardian/:guardian" element={<Guardian />} />
            <Route path="/mint/presale" element={<Mint />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
