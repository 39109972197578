import Faq from './Faq'
import Story from "./Story";
import Team from "./Team";
import PostMint from "./PostMint";
import Roadmap from './Roadmap';
import BuyNow from './BuyNow';
import Attributes from './Attributes'
function Home () {
  return (
    <div>
      <Story />
      <BuyNow />
      <Roadmap />
      <Attributes />
      <Team />
      <Faq />
      <PostMint />
    </div>
  )
}

export default Home
