import Faq from './Faq'

function NotFound () {
  return (
    <div>
      <section className="bg-green-400 lg:py-32 py-20 px-6 text-center">
        <h2 className="font-heading text-8xl text-gray-900 mb-6">404</h2>
        <h3 className="text-gray-700 font-semibold text-2xl mb-16">You have landed on a page that doesn't exist.</h3>
        <a href="/" title="Back to homepage" className="font-semibold shadow-xl flex inline-flex items-center mx-auto text-xl rounded mx-auto bg-green-700 hover:bg-green-800 text-white px-6 py-4">
          <span className="mr-3">
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
              <path d="M12.6666 8H3.33331" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M7.99998 12.6666L3.33331 7.99992L7.99998 3.33325" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </span>
          <span>Back to Homepage</span>
        </a>
      </section>
      <Faq />
    </div>
  )
}

export default NotFound;
