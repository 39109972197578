export default function Faq() {
  return (
    <section className="bg-gray-900 lg:py-32 py-20 px-6">
    <div className="max-w-2xl mx-auto">
      <h2 className="text-5xl font-gray-900 mb-4 font-heading uppercase text-center text-white">FAQ</h2>
      <div className="text-center text-gray-500 mb-12 uppercase font-semibold">Frequently Asked Questions</div>
      <div>
        <div className="">
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              I need help, where do I go?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              Please join our Discord if you need help with anything. Our team and community are standing by to help.
              If you are brand new and want to understand NFTs, Metamask, and how everything works we are more than happy to explain.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              When will I be able to mint a GOTE NFT?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              <p><strong>Presale</strong> will take place at 10:00 AM (UTC) on 14. February 2022.</p>
              <p><strong>Public sale</strong> will take place at 10:00 AM (UTC) on 16. February 2022.</p>
            </div>
          </details>
           <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              How much will The Guardians of the Elves (GOTE) NFT cost me?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              The Guardians of the Elves NFT will cost you at Presale 0.03 ETH + gas fee, but at the public sale, the price will be raised to 0.08 ETH + gas fee. It's possible that we adjust the price as demand increases.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              Will there be giveaways?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              Yes of course! Join our Discord, follow our Twitter and Instagram and we let you know.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              What blockchain will you be using?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              The Ethereum Blockchain.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              How many GOTE NFTs will be available?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              Only 10 000 GOTE NFTs will be available. Each Guardian is unique and programmatically generated from 300+ hand-drawn traits.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              Will there be a pre-sale before the mint?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              Yes, but only for our early supporters. Join the GOTE NFT Discord to find out more! Our community’s interest is our priority.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              When is the reveal?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              All GOTE NFTs will be revealed on public sale at 10:00 AM (UTC) on 16. February 2022.  At that time, please refresh metadata on Opensea to see your GOTE NFT!
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              Do I own the GOTE NFT after I mint it?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              Yes, you are now the sole owner of the GOTE NFT. You may use it for merchandising, logo branding, or simply to put it as your profile picture! Your Guardian of the Elves is YOURS.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              Where will my GOTE NFT be held?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              Once minted, you'll just need to connect your wallet to OpenSea to view your NFTs.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              What does „minting“ mean?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              Minting an NFT means your digital asset will be kind of coded in the blockchain and have a specific codification so your NFT is one of kind and no one can copy it and it's also available to transaction.
            </div>
          </details>
          <details className="mb-4">
            <summary className="font-black bg-green-400 hover:bg-green-300 text-lg rounded-md py-2 px-4 shadow">
              Where can I view my NFTs?
            </summary>
            <div className="p-4 leading-relaxed text-white text-md">
              Once you mint you GOTE NFT, you just need to log into your Opensea account to view your NFTs.
            </div>
          </details>
        </div>
      </div>
    </div>
    </section>
  )
}
