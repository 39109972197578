import React from "react";

export default function Team () {
  return (
    <section className="bg-green-400 lg:py-32 py-20 px-6">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-5xl font-gray-900 mb-8 font-heading uppercase">Our team</h2>
        <div className="grid gap-16 lg:grid-cols-2 grid-cols-1">
          <div>
            <div className="flex items-center justify-start mb-4">
              <img src="./iR00st13.png" className="rounded-lg shadow-lg h-16 w-16 mr-4" />
              <div>
                <h4 className="font-heading text-gray-900 uppercase text-3xl">iR00st13</h4>
                <p className="text-sm font-black text-green-900 uppercase leading-relaxed">The Artist</p>
              </div>
            </div>
            <div className="flex-1">
              <div>Graduated from a recognized college of art. Independent artist and visual graphic designer for years.</div>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-start mb-4">
              <img src="./slides/slide_8.png" className="rounded-lg shadow-lg h-16 w-16 mr-4" />
              <div>
                <h4 className="font-heading text-gray-900 uppercase text-3xl">Peekon</h4>
                <p className="text-sm font-black text-green-900 uppercase leading-relaxed">The Developer</p>
              </div>
            </div>
            <div className="flex-1">
              <div>Professional web & software engineer for nearly 15 years developing experience with a background in design.</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
