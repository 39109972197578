import RandomImage from "./RandomProfile";
import {NavLink} from "react-router-dom";
import React from "react";

function Story () {
  return (
    <section className="bg-green-400 px-6 bg-cover bg-bottom" style={{backgroundImage: "url(" + "./green-background.jpg" + ")"}}>
      <div className="lg:flex block xl:gap-12 lg:gap-0 container mx-auto items-end">
        <div className="xl:w-6/12 lg:w-5/12 w-full lg:m-0 mb-12">
          <div className="xl:py-32 lg:py-12 pt-16 pb-0">
            <h2 className="text-5xl font-gray-900 mb-6 font-heading uppercase">Story</h2>
            <p className="mb-8 text-lg leading-relaxed">
              Most of us haven't ever seen them, many of us haven't even heard of them but the magical creatures - the elves, have always lived among us.
              Their home is a place called the Burnedland Forest. Nobody knows exactly where it is, but because of recent human activity nearby they don't feel safe anymore.
              So they need guardians now. There are 10 000 of them. They are the Guardians of the Elves.
              Some appear like ordinary elves. Some look weird. Some of them are common, but few among them are very rare breeds.
            </p>

            <NavLink to="/mint" className="px-12 py-6 flex items-center mx-auto shadow-xl justify-center font-heading uppercase hover:bg-green-100 bg-white text-4xl rounded-2xl border-2 border-gray-900 text-gray-800">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 490 490" className="h-10 w-10 fill-current text-green-400 mr-4">
                <path d="M468.2,358.9H121.7c-11.4,0-20.8-9.4-20.8-20.8V88.4c0-28.1-14.6-46.8-36.4-46.8H20.8C9.4,41.6,0,32.3,0,20.8 S9.4,0,20.8,0h44.7c45.8,0,77,36.4,77,87.4v231h326.7c11.4,0,20.8,9.4,20.8,20.8C489,349.6,479.6,358.9,468.2,358.9z"></path>
                <path d="M172.7,490c-33.3,0-60.3-27-60.3-60.3s27-60.3,60.3-60.3s60.3,27,60.3,60.3S206,490,172.7,490z M172.7,410.9 c-10.4,0-18.7,8.3-18.7,18.7c0,10.4,8.3,18.7,18.7,18.7s18.7-8.3,18.7-18.7C192.5,419.3,184.1,410.9,172.7,410.9z"></path>
                <path d="M416.1,490c-33.3,0-60.3-27-60.3-60.3s27-60.3,60.3-60.3s60.3,27,60.3,60.3S449.4,490,416.1,490z M416.1,410.9 c-10.4,0-18.7,8.3-18.7,18.7c0,10.4,8.3,18.7,18.7,18.7c10.4,0,18.7-8.3,18.7-18.7C434.9,419.3,426.5,410.9,416.1,410.9z"></path>
                <path d="M444.2,292.3H195.6c-11.4,0-20.8-9.4-20.8-20.8V23.9c0-11.4,9.4-20.8,20.8-20.8h247.6c11.4,0,20.8,9.4,20.8,20.8v247.6 C464,283,454.6,292.3,444.2,292.3z M216.4,250.7h207v-207h-207V250.7z"></path>
              </svg>
              <span>MINT HERE</span>
            </NavLink>

          </div>
        </div>
        <div className="xl:w-6/12 lg:w-7/12 w-full">
          <RandomImage className="w-full" />
        </div>
      </div>
    </section>
  )
}
export default Story
