import React from "react"

const Roadmap = () => {
  return (
    <section className="bg-green-400 px-0 lg:py-32 py-20 px-6">
      <h2 className="text-5xl text-gray-900 mb-12 font-heading uppercase text-center">ROADMAP</h2>
      <div className="container mx-auto">
        <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-white">
          <div className="flex flex-row-reverse md:contents">
            <div className="bg-gray-900 col-start-1 col-end-5 px-6 py-4 rounded-xl my-4 ml-auto shadow-md md:text-right text-left md:w-8/12 w-full">
              <h3 className="font-heading uppercase text-lg mb-1 text-green-400">14. February 2022</h3>
              <p>
                PRE-SALE for our community, price 0.03 ETH
              </p>
            </div>
            <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-600 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-900 shadow"></div>
            </div>
          </div>
          <div className="flex md:contents">
            <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-600 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-900 shadow"></div>
            </div>
            <div className="bg-gray-900 col-start-6 col-end-10 px-6 py-4 rounded-xl my-4 mr-auto shadow-md md:w-8/12 w-full">
              <h3 className="font-heading uppercase text-lg mb-1 text-green-400">16. February 2022</h3>
              <p>
                PUBLIC LAUNCH - Anyone can mint 1-20 of the remaining GOTE NFTs for 0.08 ETH each. Revealing all GOTE NFTs
              </p>
            </div>
          </div>
          <div className="flex flex-row-reverse md:contents">
            <div className="bg-gray-900 col-start-1 col-end-5 px-6 py-4 rounded-xl my-4 ml-auto shadow-md md:w-8/12 w-full md:text-right text-left">
              <h3 className="font-heading uppercase text-lg mb-1 text-green-400">Giveaways</h3>
              <p>
                Join our Discord, follow our Twitter and Instagram and we let you know.
              </p>
            </div>
            <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-600 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-900 shadow"></div>
            </div>
          </div>
          <div className="flex md:contents">
            <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-600 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-900 shadow"></div>
            </div>
            <div className="bg-gray-900 col-start-6 col-end-10 px-6 py-4 rounded-xl my-4 mr-auto shadow-md md:w-8/12 w-full">
              <h3 className="font-heading uppercase text-lg mb-1 text-green-400">100% sold</h3>
              <p>
                Website pages for GOTE characters
              </p>
            </div>
          </div>
          <div className="flex flex-row-reverse md:contents">
            <div className="bg-gray-900 col-start-1 col-end-5 px-6 py-4 rounded-xl my-4 ml-auto shadow-md md:text-right text-left md:w-8/12 w-full">
              <h3 className="font-heading uppercase text-lg mb-1 text-green-400">10 ETH Donation</h3>
              <p>
                10 ETH Donation to CHARITY of communities CHOICE
              </p>
            </div>
            <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-600 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-900 shadow"></div>
            </div>
          </div>
          <div className="flex md:contents">
            <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-600 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-900 shadow"></div>
            </div>
            <div className="bg-gray-900 col-start-6 col-end-10 px-6 py-4 rounded-xl my-4 mr-auto shadow-md md:w-8/12 w-full">
              <h3 className="font-heading uppercase text-lg mb-1 text-green-400">60 ETH Raffle</h3>
              <p>
                There will be 1 winner. The winner price will be 60 ETH divided by Guardian RANK. If the winner Guardian's RANK is 2, the price will be 30 ETH. If you got multiple Guardians, you can use the strongest Guardian.
              </p>
            </div>
          </div>
          <div className="flex flex-row-reverse md:contents">
            <div className="bg-gray-900 col-start-1 col-end-5 px-6 py-4 rounded-xl my-4 ml-auto shadow-md md:w-8/12 w-full md:text-right text-left">
              <h3 className="font-heading uppercase text-lg mb-1 text-green-400">Rarity.tools</h3>
              <p>
                Get listed on Rarity.tools
              </p>
            </div>
            <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-600 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-900 shadow"></div>
            </div>
          </div>
          <div className="flex md:contents">
            <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-600 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-900 shadow"></div>
            </div>
            <div className="bg-gray-900 col-start-6 col-end-10 px-6 py-4 rounded-xl my-4 mr-auto shadow-md md:w-8/12 w-full">
              <h3 className="font-heading uppercase text-lg mb-1 text-green-400">Additional PLANS</h3>
              <p>
                 Additional PLANS of communities CHOICE.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Roadmap
